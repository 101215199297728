import styled from 'styled-components'
import {
  primaryColor,
  secondaryColor,
  paragraphColor,
  strongColor,
  sectionInlineSize,
  smBreakPoint,
  xsBreakPoint,
  defaultColor,
} from '~src/common/cssTips'
import sCircle from '~src/assets/image/s-circle.svg'

export const Article = styled.article`
  h1 {
    position: relative;
    font-size: 30px;
    font-weight: normal;
    line-height: 45px;
    padding-bottom: 20px;
    margin-top: 80px;
    margin-bottom: 30px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 46px;
      height: 3px;
      background-color: ${primaryColor};
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  ul,
  ol {
    padding-left: 1.5em;
  }

  .bold-list ul,
  .bold-list ol {
    list-style: none;
  }

  .bold-list ol {
    counter-reset: list-count 0;
  }

  .bold-list ol > li {
    counter-increment: list-count;
    &::before {
      content: counter(list-count) '.';
      position: relative;
      margin-left: -1.5em;
      margin-right: 0.5em;
      color: ${strongColor};
      font-weight: bold;
    }
  }

  .bold-list ul > li {
    &::before {
      content: url(${sCircle});
      display: inline-block;
      position: relative;
      margin-left: -1em;
      margin-right: 0.5em;
      width: 8px;
      height: 8px;
      transform: translate(-4px, -2px);
    }
  }

  p,
  li {
    line-height: 30px;
    margin: 14px 0;
  }

  br {
    line-height: 38px;
  }

  p,
  li {
    opacity: 0.8;
    font-size: 16px;
    color: ${paragraphColor};
    line-height: 30px;
    font-family: inherit;
  }

  strong {
    color: ${strongColor};
  }

  a {
    color: ${primaryColor};
    text-decoration: none;
    &:hover {
      color: ${secondaryColor};
    }
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a {
    text-decoration: underline;
  }

  h1 + h1,
  h2 + h2,
  h3 + h3,
  h4 + h4 {
    margin-top: 0;
  }
`

export const FeaturesArticle = styled(Article)`
  margin-bottom: 90px;
  p,
  li {
    opacity: 1;
    color: ${defaultColor};
  }
  p {
    margin: 30px 0;
  }
  .gatsby-resp-image-wrapper {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`

export const FeaturesSection = styled.section`
  ${sectionInlineSize}
  padding-top: 80px;
  margin-top: -15vh;

  @media screen and (max-width: ${smBreakPoint}) {
    padding-top: 60px;
  }

  @media screen and (max-width: ${xsBreakPoint}) {
    padding-top: 40px;
  }
`
